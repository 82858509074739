<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-radio-group
          v-model="queryParam.personnel_type"
          button-style="solid"
          @change="handleRefresh(true)"
      >
        <a-radio-button :value="0">
          司机
        </a-radio-button>
        <a-radio-button :value="1">
          押运员
        </a-radio-button>
      </a-radio-group>


      <a-input-search
          enter-button
          allowClear
          style="max-width: 300px; min-width: 150px;margin-left:10px"
          v-model="queryParam.keyword"
          placeholder="请输入员工姓名"
          @search="onSearch"
      />

      <span style="margin-left: 40px;font-size: 20px">总开支：<span style="color: red">¥</span>{{
          net_salary_total
        }}</span>


      <span
          style="float: right"
      >
        排序：
      <a-radio-group
          v-model="queryParam.order_net_salary"
          button-style="solid"
          @change="handleRefresh(true)"
      >
        <a-radio-button value="desc">
            由高至低
        </a-radio-button>
        <a-radio-button value="asc">
            由低至高
        </a-radio-button>
      </a-radio-group>
      </span>

    </div>
    <s-table
        ref="table"
        rowKey="payroll_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
        :scroll="{y:450}"
    >
      <div slot="status" slot-scope="text, item">
        <a-tag v-if="item.status==0">待审核</a-tag>
        <a-tag v-if="item.status==1" color="orange">待核对</a-tag>
        <a-tag v-if="item.status==2" color="green">待发放</a-tag>
        <a-tag v-if="item.status==3" color="cyan">待签收</a-tag>
        <a-tag v-if="item.status==4" color="blue">已完成</a-tag>
        <a-tag v-if="item.status==5" color="red">核对有误</a-tag>
      </div>
      <div slot="cert_status" slot-scope="text, item">
        <a-tag color="green">正常</a-tag>
      </div>
      <div slot="personnel_name" slot-scope="text, item">
        {{ text }}
      </div>
      <div slot="personnel_type" slot-scope="text, item">
        <a-tag color="blue" v-if="item.personnel_type===0">司机</a-tag>
        <a-tag color="orange" v-if="item.personnel_type===1">押运员</a-tag>
      </div>

      <div slot="net_salary" slot-scope="text, item">
        <span style="color: rgb(208, 5, 5)">¥</span> {{ text }}
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <!--        <a-popconfirm-->
        <!--            v-if="item.status==0"-->
        <!--            title="确认标记已审？"-->
        <!--            ok-text="确定"-->
        <!--            cancel-text="取消"-->
        <!--            @confirm="handleChangeStauts(item,1)"-->
        <!--        >-->
        <!--          <a>标记已审</a>-->
        <!--        </a-popconfirm>-->
        <!--        <a-popconfirm-->
        <!--            v-if="item.status==2"-->
        <!--            title="确认已发放？"-->
        <!--            ok-text="确定"-->
        <!--            cancel-text="取消"-->
        <!--            @confirm="handleChangeStauts(item,3)"-->
        <!--        >-->
        <!--          <a>已发放</a>-->
        <!--        </a-popconfirm>-->


        <!--        <a @click="handleAdditiona(item)">额外费用</a>-->
        <a v-action:show @click="handleDetails(item)">查看明细</a>
      </div>
    </s-table>
    <PayrollDetailsModel
        ref="PayrollDetailsModel"
        @handleSuccess="handleRefresh"
    />
  </a-card>
</template>

<script>
import * as Api from '@/api/personnel/payroll'
import {STable} from '@/components'
import PayrollDetailsModel from "./modules/PayrollDetailsModel";

export default {
  name: 'Index',
  components: {
    STable,
    PayrollDetailsModel
  },
  data() {
    return {
      // 标题
      title: '',

      loading: false,
      // 查询参数
      queryParam: {
        personnel_type: 0,
        payroll_type: 1,
        keyword: '',
        month_time: '',
        order_net_salary: 'desc'
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '状态',
          dataIndex: 'status',
          width: 80,
          scopedSlots: {customRender: 'status'}
        },

        {
          title: '员工姓名',
          width: 200,
          dataIndex: 'personnel_name',
          scopedSlots: {customRender: 'personnel_name'}
        },
        {
          title: '职位',
          width: 80,
          dataIndex: 'personnel_type',
          scopedSlots: {customRender: 'personnel_type'}
        },
        {
          title: '账期',
          width: 100,
          dataIndex: 'month_str',
          scopedSlots: {customRender: 'month_str'}
        },
        {
          title: '总开支',
          dataIndex: 'net_salary',
          scopedSlots: {customRender: 'net_salary'}
        },
        {
          title: '操作',
          width: 300,
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      net_salary_total: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              this.net_salary_total = response.data.list.net_salary_total
              return response.data.list.list
            })
      }
    }
  },
  created() {

  },
  methods: {

    handleAdditiona(item) {
      this.$refs.AdditionaModel.queryParam.payroll_id = item.payroll_id
      this.$refs.AdditionaModel.queryParam.payroll_type = 1
      this.$refs.AdditionaModel.open(item)
    },

    handleCommission(item) {
      this.$refs.CommissionModel.queryParam.personnel_id = item.personnel_id
      this.$refs.CommissionModel.queryParam.personnel_type = item.personnel_type
      this.$refs.CommissionModel.queryParam.month_time = item.month_time
      this.$refs.CommissionModel.open(item)
    },

    setMonth(month) {
      this.queryParam.month_time = month
      this.handleRefresh(true)
    },

    /**
     * 查看明细
     */
    handleDetails(item) {
      this.$refs.PayrollDetailsModel.open(item)
    },

    handleCancel() {
      this.visible = false
    },

    async handleChangeStauts({payroll_id}, status) {
      await Api.changeStatus({payroll_id, status})
      this.handleRefresh()
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
